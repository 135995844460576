<template>
      <div class="container">
        <div class="columns">
          <div class="column">
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="u in Tutorial_Data_Array" :key="u.id">
                  <td>{{u.id}}</td>
                  <td>{{u.title}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3">
                    <nav class="pagination">
                      <ul>
                        <li><a v-on:click="prev()" class="button">Previous</a></li>
                        <li v-for="p in pageCount" :key="p.id">
                          <a v-if="pageNo == p" v-on:click="page(p)" class="button is-primary">{{p}}</a>
                          <a v-else v-on:click="page(p)" class="button">{{p}}</a>
                        </li>
                        <li><a v-on:click="next()" class="button">Next page</a></li>
                      </ul>
                    </nav>
                  </td>
                </tr>
                <!-- <tr>
                  <td colspan="3">
                    <button  v-on:click="first()" class="button">First</button>
                    <button v-on:click="prev()" class="button">Prev</button>
                    <span style="padding-top:1em;">{{pageNo}}/{{pageCount}}</span>
                    <button v-on:click="next()" class="button">Next</button>
                    <button v-on:click="last()" class="button">Last</button>
                  </td>
                </tr> -->
              </tfoot>
            </table>
          </div>
        </div>
      </div>
</template>

<script>
import Vedio_Data from '../../assets/data/Tutoriales.json'
const Tutorial_Data = Vedio_Data.Tutoriales;
export default {
   data () {
    return {
      Tutorial_Data,
      Tutorial_Data_Array: [],
      pageNo: 1,
      pageSize: 5,
      pageCount: 0  
    }
  },
   methods: {
    init: function(){ 
      this.virtualService({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      });
    },
    page: function(pageNo) { 
     
     this.virtualService({
        pageNo: pageNo,
        pageSize: this.pageSize
      });
    },
    // first: function() {
    //     this.pageNo = 1; 
    //     this.virtualService({
    //       pageNo:this.pageNo,
    //       pageSize:this.pageSize
    //     });
    // },
    // last: function() {
    //     this.pageNo = this.pageCount; 
    //     this.virtualService({
    //       pageNo:this.pageNo,
    //       pageSize:this.pageSize
    //     });
    // },
    prev: function() {
      if(this.pageNo > 1){ 
        this.pageNo -= 1; 
        this.virtualService({
          pageNo:this.pageNo,
          pageSize:this.pageSize
        });
      }
    },
    next: function() {
      if(this.pageNo < this.pageCount) {
        this.pageNo += 1;
        this.virtualService({
          pageNo:this.pageNo,
          pageSize:this.pageSize
        });
      }
    },
    virtualDataFromDb: function() {
      // var users = [];
      // for(var i = 1; i <= 105; i++){
      //   var user = {
      //     id: "US"+i,
      //     firstName: i + "USER",
      //     lastName: "RESU"+i
      //   };
      //   users.push(user);
      // }
      this.Tutorial_Data_Array = Tutorial_Data
      return this.Tutorial_Data_Array;
    },
    count: function(){
      return this.virtualDataFromDb().length;
    },
    queryFromVirtualDB: function(condition, startRow, endRow){
      var result = [];
      condition = {};
      var data = this.virtualDataFromDb();
      var count = this.count(condition);
      for(var i = startRow - 1; i < endRow; i++) {
        if(i < count){
          result.push(data[i]);
        }
      }
      return result;
    },
    virtualService: function(params){
      
      var condition = {};
      var pageNo = params.pageNo;
      var pageSize = params.pageSize;
      var pageCount = Math.ceil(this.count(condition) / pageSize);
				
      if (pageNo == 0) pageNo = 1;
      if (pageNo < 0) pageNo = pageCount;
      else if (pageNo > pageCount) pageNo = pageCount;
      var startRow = (pageNo - 1) * pageSize + 1;
      var endRow = startRow + pageSize - 1;
      var data = this.queryFromVirtualDB(condition, startRow, endRow);
      
      // set result
      this.Tutorial_Data_Array = data;
      this.pageNo = pageNo;
      this.pageCount = pageCount;
      
    }
  },
  created: function(){
    // this.init();
  },
  // ready
  mounted: function(){
    this.init();
  }
};
</script>

<style scoped>
.is-primary{
  background-color: #d33327;
  color: #ffffff;
}
.pagination ul > li{
  cursor: pointer;
}
/* @import url("https://cdnjs.cloudflare.com/ajax/libs/bulma/0.2.3/css/bulma.min.css"); */
</style>